import React, { ReactElement, useState } from "react";
import { observer } from "mobx-react";
import {
  contents,
  spacing,
  Header2,
  Body2,
  borderRadius,
  Subtitle1,
  Back,
  More,
  boxShadow,
} from "ms-ui";
import styled from "styled-components";
import { winOpen } from "src/func/window";
import { SwiperOptions } from "swiper/types";
import { Swiper, SwiperSlide } from "src/components/Swiper/Swiper";

interface IMedia {
  list: { link: string; title: ReactElement; press: string }[];
}

export const Media = observer(({ list }: IMedia) => {
  const [swiper, setSwiper] = useState<any>();
  const [selected, setSelected] = useState(0);

  const swiperOptions: SwiperOptions = {
    on: {
      _swiper(s) {
        setSwiper(s);
      },
      slideChange(s) {
        setSelected(s.activeIndex);
      },
    },
  };

  const isFirst = selected === 0;
  const isLast = selected === list.length - 1;

  return (
    <>
      <StDiv1>
        <StDiv2>
          <StDiv3>
            <StDiv4>미디어</StDiv4>
            <StDiv5>
              미디어를 통해 조명된 모두의셔틀
              <br />
              최신 소식을 확인하실 수 있습니다.
            </StDiv5>
          </StDiv3>
          <div
            style={{
              padding: `0px ${spacing["32"].value} ${spacing["32"].value} ${spacing["32"].value}`,
            }}
          >
            <Swiper {...swiperOptions} grabCursor={true} spaceBetween={24}>
              {list.map((item, i) => (
                <SwiperSlide key={i}>
                  <div
                    onClick={() => {
                      winOpen(item.link);
                    }}
                    style={{
                      display: `flex`,
                      width: `100%`,
                      height: `300px`,
                      padding: `${spacing["24"].value}`,
                      flexDirection: `column`,
                      justifyContent: `space-between`,
                      borderRadius: `${borderRadius["5"].value}`,
                      background: `${contents["secondary"].value}`,
                    }}
                  >
                    <StDiv9>{item.title}</StDiv9>
                    <StDiv10>{item.press}</StDiv10>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <StNavigation>
              {!isFirst && (
                <div
                  className={"control prev"}
                  onClick={() => {
                    swiper?.slidePrev();
                  }}
                >
                  <Back color={contents.subtitle.value} />
                </div>
              )}

              {!isLast && (
                <div
                  className={"control next"}
                  onClick={() => swiper?.slideNext()}
                >
                  <More color={contents.subtitle.value} />
                </div>
              )}
            </StNavigation>
          </div>
        </StDiv2>
      </StDiv1>
    </>
  );
});

const StNavigation = styled.div`
  .control {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    background: ${contents.secondary.value};
    box-shadow: ${boxShadow[500].value};
    border-radius: 20px;
    margin-top: -20px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .prev,
  .next {
    background-size: 22px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transform: translateY(45px);
  }

  .prev {
    left: 15px;
  }

  .next {
    right: 15px;
  }
`;

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

const StDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  flex: 1 0 0;
  background: ${contents.background.value};
  width: 100%;
  position: relative;
`;

const StDiv3 = styled.div`
  display: flex;
  padding-top: ${spacing[24].value};
  flex-direction: column;
  align-items: center;
  gap: ${spacing[12].value};
  width: 100%;
`;

const StDiv4 = styled.div`
  ${Header2};
  align-self: stretch;
  color: ${contents.primary.value};
  text-align: center;
`;

const StDiv5 = styled.div`
  ${Body2};
  align-self: stretch;
  color: ${contents.subtitle.value};
  text-align: center;
`;

const StDiv9 = styled.div`
  ${Header2};
  width: 100%;
  color: ${contents.primary.value};
`;

const StDiv10 = styled.div`
  ${Subtitle1};
  color: ${contents.accent.value};
  text-align: center;
`;
