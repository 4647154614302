import { Nav } from "ms-ui";
import { useURI } from "ms_router";

import { BackHistory } from "src/func/url";
import { MemberShip } from "../Main/views/MainSection/Membership";
import { IntroHistory } from "./views/IntroHistory";
import { IntroInfo } from "./views/IntroInfo";
import { FooterComp } from "../Main/views/Footer";
import { MediaView } from "./views/Media";

const Intro = () => {
  const { navigate } = useURI();
  return (
    <>
      <Nav
        title="회사소개"
        left={{
          type: "back",
          onClick: () => {
            BackHistory(navigate, "/");
          },
        }}
      />
      <IntroInfo />
      <MemberShip />
      <IntroHistory />
      <MediaView />
      <FooterComp />
    </>
  );
};

export default Intro;
