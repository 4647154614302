import { Media } from "./MediaView";

export const MediaView = () => {
  const arr = [
    {
      link: "http://www.digitaltoday.co.kr/news/articleView.html?idxno=253470",
      title: (
        <>
          모두의셔틀, 행정안전부
          <br />
          협업성공사례 영상 공모전
          <br />
          장관상 수상
        </>
      ),
      press: "Digital Today",
    },
    {
      link: "https://www.mk.co.kr/news/it/view/2020/10/1066410/",
      title: (
        <>
          모두의셔틀, 10명 정도만
          <br />
          모으면 출퇴근 셔틀버스가 온다
        </>
      ),
      press: "매일경제",
    },
    {
      link: "http://www.digitaltoday.co.kr/news/articleView.html?idxno=250638",
      title: (
        <>
          [모빌리티 기업탐방]
          <br />
          모두의셔틀 "국내 대표 직장인 통근 모빌리티 서비스 되겠다"
        </>
      ),
      press: "Digital Today",
    },
    {
      link: "https://news.naver.com/main/read.nhn?mode=LSD&mid=sec&sid1=105&oid=014&aid=0004419779",
      title: (
        <>
          모두의셔틀, 통근 셔틀버스
          <br />
          종로·상암으로 확대
        </>
      ),
      press: "파이낸셜뉴스",
    },
    {
      link: "https://news.naver.com/main/read.nhn?mode=LSD&mid=sec&sid1=105&oid=014&aid=0004387660",
      title: (
        <>
          네이버 D2SF, 3개 기술
          <br />
          스타트업에 투자
        </>
      ),
      press: "파이낸셜뉴스",
    },
  ];

  return <Media list={arr} />;
};
