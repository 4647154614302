import React from "react";
import { observer } from "mobx-react";
import {
  Paragraph,
  spacing,
  contents,
  Header2,
  Body2,
  foundation,
  Header3,
  Subtitle2,
  Body1,
} from "ms-ui";
import styled from "styled-components";

export const IntroHistory = observer(() => {
  return (
    <>
      <Paragraph
        noMargin={true}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: `${spacing[24].value} `,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: `${spacing[12].value} `,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: `${spacing[12].value} `,
              width: "100%",
            }}
          >
            <StDiv1>{`연혁`}</StDiv1>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: `${spacing[12].value} `,
              width: "100%",
            }}
          >
            <StDiv2>
              {`모두의셔틀은 2017년 서비스 오픈 이후
지금까지 꾸준히 성장해왔습니다.`}
            </StDiv2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: `${spacing[32].value} `,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: `${spacing[12].value} `,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  paddingBottom: `${spacing[12].value} `,
                  borderBottom: `1px solid ${contents.tertiary.value} `,
                  width: "100%",
                }}
              >
                <StList1_1_1>{`2020`}</StList1_1_1>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`03`}</StList1_2_1>
                  <StList1_2_2>{`네이버 D2SF 투자유치`}</StList1_2_2>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`01`}</StList1_2_1>
                  <StList1_2_2>{`운행 출근길 200개 돌파`}</StList1_2_2>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: `${spacing[12].value} `,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  paddingBottom: `${spacing[12].value} `,
                  borderBottom: `1px solid ${contents.tertiary.value} `,
                  width: "100%",
                }}
              >
                <StList1_1_1>{`2019`}</StList1_1_1>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`12`}</StList1_2_1>
                  <StList1_2_2>{`군포/일산/서울 성북 운행 확대`}</StList1_2_2>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`08`}</StList1_2_1>
                  <StList1_2_2>{`DSC/슈미트/L&S 투자유치`}</StList1_2_2>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`06`}</StList1_2_1>
                  <StList1_2_2>{`송도/부천 지역 운행 확대`}</StList1_2_2>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`01`}</StList1_2_1>
                  <StList1_2_2>
                    {`안양/고양 원흥/시흥/용인 지역 운행 확대`}
                  </StList1_2_2>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: `${spacing[12].value} `,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  paddingBottom: `${spacing[12].value} `,
                  borderBottom: `1px solid ${contents.tertiary.value} `,
                  width: "100%",
                }}
              >
                <StList1_1_1>{`2018`}</StList1_1_1>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`09`}</StList1_2_1>
                  <StList1_2_2>{`SBA(서울산업진흥원) 투자유치`}</StList1_2_2>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`06`}</StList1_2_1>
                  <StList1_2_2>
                    {`경기 광주/동탄2/김포/구리/수원 지역 운행 확대`}
                  </StList1_2_2>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`02`}</StList1_2_1>
                  <StList1_2_2>
                    {`위례/고덕/분당/남양주 지역 운행 확대`}
                  </StList1_2_2>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`01`}</StList1_2_1>
                  <StList1_2_2>
                    {`본엔젤스/디캠프/프라이머 투자유치`}
                  </StList1_2_2>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: `${spacing[12].value} `,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  paddingBottom: `${spacing[12].value} `,
                  borderBottom: `1px solid ${contents.tertiary.value} `,
                  width: "100%",
                }}
              >
                <StList1_1_1>{`2017`}</StList1_1_1>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`07`}</StList1_2_1>
                  <StList1_2_2>
                    {`대한민국 모바일 어워드 ‘7월의 모바일’ 선정`}
                  </StList1_2_2>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`06`}</StList1_2_1>
                  <StList1_2_2>{`디캠프 ‘6월 디데이’ 우승`}</StList1_2_2>
                </div>
                <div
                  style={{
                    display: "flex",
                    height: "24px",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`02`}</StList1_2_1>
                  <StList1_2_2>{`인천/서울 구로, 관악 운행 확대`}</StList1_2_2>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: `${spacing[8].value} `,
                    width: "100%",
                  }}
                >
                  <StList1_2_1>{`01`}</StList1_2_1>
                  <StList1_2_2>{`주식회사 모두의셔틀 법인 설립`}</StList1_2_2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paragraph>
    </>
  );
});

const StDiv1 = styled.div`
  ${Header2};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
  white-space: pre-line;
`;

const StDiv2 = styled.div`
  ${Body2};
  color: ${contents.subtitle.value};
  text-align: center;
  width: fit-content;
  white-space: pre-line;
`;

const StList1_1_1 = styled.div`
  ${Header3};
  flex: 1 0 0;
  color: ${foundation.normal.secondary.value};
  white-space: pre-line;
`;

const StList1_2_1 = styled.div`
  ${Subtitle2};
  width: 19px;
  color: ${contents.accent.value};
  white-space: pre-line;
`;

const StList1_2_2 = styled.div`
  ${Body1};
  flex: 1 0 0;
  color: ${contents.subtitle.value};
  white-space: pre-line;
`;
