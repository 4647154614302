import React from "react";
import { observer } from "mobx-react";
import {
  spacing,
  contents,
  Subtitle3,
  Header1,
  foundation,
  Body2,
} from "ms-ui";
import styled from "styled-components";
import { ms_file } from "src/constants/addr";

export const IntroInfo = observer(() => {
  return (
    <>
      <StDiv1>
        <StDiv2>
          <StDiv3>국내 1위 출퇴근 공유셔틀 멤버십</StDiv3>
          <StDiv4>
            <StDiv5>모빌리티 서비스</StDiv5>
            <StDiv6>모두의셔틀</StDiv6>
            <StDiv7>함께하는 여정이 사람들의 이동을 혁신합니다.</StDiv7>
          </StDiv4>
        </StDiv2>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  height: 432px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StDiv2 = styled.div`
  display: flex;
  width: 100%;
  height: 432px;
  padding: 300px ${spacing[16].value} 0px ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[8].value};
  flex-shrink: 0;
  background: url(${ms_file}/image/background/IntroNew.png) lightgray 50% /
    cover no-repeat;
`;

const StDiv3 = styled.div`
  ${Subtitle3};
  color: ${contents.secondary.value};
  width: fit-content;
`;

const StDiv4 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4].value};
  width: fit-content;
`;

const StDiv5 = styled.div`
  ${Header1};
  color: ${contents.secondary.value};
  width: fit-content;
`;

const StDiv6 = styled.div`
  ${Header1};
  color: ${foundation.normal.secondary.value};
  width: fit-content;
`;

const StDiv7 = styled.div`
  ${Body2};
  color: ${contents.secondary.value};
  width: fit-content;
`;
